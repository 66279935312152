import React, { useState, useEffect } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Menu, X } from "lucide-react"; // Icons for Hamburger
import "react-loading-skeleton/dist/skeleton.css";
import { FaInstagram, FaFacebookF } from "react-icons/fa"; // Social Icons

const Hero = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showScrollText, setShowScrollText] = useState(true);

  useEffect(() => {
    if (typeof window === "undefined") return; // Ensure client-side execution

    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement?.scrollHeight || 0;

      if (scrollY + windowHeight >= documentHeight - 10) {
        setShowScrollText(false);
      } else {
        setShowScrollText(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <LazyLoadComponent>
      {/* Sticky Header */}
      <header className="fixed top-0 left-0 w-full bg-black bg-opacity-90 z-50 px-6 py-4 flex flex-col text-white">
        {/* Marquee Announcement */}
        <div className="w-full bg-gray-900 text-red-50 py-2 overflow-hidden">
          <p className="marquee font-semibold text-sm md:text-md tracking-wide">
            All island delivery free over 2000 LKR purchase 🔥 Limited time
            offer!
          </p>
        </div>
        <div className="flex justify-between items-center">
          {/* Logo */}
          <img
            src="/Logo_Coffidu_Transparent.png"
            alt="Coffidu Logo"
            className="w-28 md:w-32"
          />

          {/* Navigation */}
          <nav className="hidden md:flex space-x-6 text-sm">
            <a href="#home" className="hover:text-gray-400">
              HOME
            </a>
            <a href="#about" className="hover:text-gray-400">
              ABOUT US
            </a>
            <a href="#products" className="hover:text-gray-400">
              PRODUCTS
            </a>
            <a href="#feeds" className="hover:text-gray-400">
              FEEDBACKS
            </a>
            <a href="#contact" className="hover:text-gray-400">
              CONTACT
            </a>
          </nav>

          {/* Hamburger Menu (Mobile) */}
          <div className="md:hidden">
            <button onClick={() => setMenuOpen(!menuOpen)}>
              {menuOpen ? <X size={28} /> : <Menu size={28} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {menuOpen && (
          <div className="absolute top-16 left-0 w-full bg-black text-white flex flex-col items-center space-y-4 py-4 shadow-lg">
            <a
              href="#home"
              className="hover:text-gray-400"
              onClick={() => setMenuOpen(false)}
            >
              HOME
            </a>
            <a
              href="#about"
              className="hover:text-gray-400"
              onClick={() => setMenuOpen(false)}
            >
              ABOUT US
            </a>
            <a
              href="#products"
              className="hover:text-gray-400"
              onClick={() => setMenuOpen(false)}
            >
              PRODUCTS
            </a>
            <a
              href="#feeds"
              className="hover:text-gray-400"
              onClick={() => setMenuOpen(false)}
            >
              FEEDBACKS
            </a>
            <a
              href="#contact"
              className="hover:text-gray-400"
              onClick={() => setMenuOpen(false)}
            >
              CONTACT
            </a>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <div
        id="home"
        className="relative flex h-screen items-center justify-center bg-black text-white px-6"
      >
        {/* Main Content */}
        <div className="text-center">
          <h1
            className="text-5xl md:text-8xl font-extrabold"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            COFFIDU
          </h1>
          <p className="text-xs md:text-sm text-gray-400 tracking-wide mt-2">
            MADE WITH LOVE IN MAWANELLA . SRI LANKA
          </p>
        </div>

        {/* Scroll Indicator */}
        {showScrollText && (
          <div className="fixed bottom-6 right-6 rotate-90 text-xs tracking-widest text-gray-400 transition-opacity duration-300">
            SCROLL ↓
          </div>
        )}

        {/* Fixed Social Links */}
        <div className="fixed bottom-6 left-6 flex flex-col space-y-3 text-gray-400">
          <a
            href="https://www.instagram.com/coffidulife?igsh=MXc4NmsyYmFqa3E0Mg%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white"
          >
            <FaInstagram size={20} />
          </a>
          <a
            href="https://www.facebook.com/share/196idvBJoY/?mibextid=wwXIfr"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-white"
          >
            <FaFacebookF size={20} />
          </a>
        </div>
      </div>

      {/* Styles for Marquee */}
      <style>
        {`
          .marquee {
            white-space: nowrap;
            display: inline-block;
            animation: marquee 12s linear infinite;
          }

          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </LazyLoadComponent>
  );
};

export default Hero;

import React, { useEffect, useState, useRef } from "react";
import Hero from "../components/landing_pages/hero";
import Ribbon from "../components/landing_pages/ribbon";
import LeaveBehind from "../components/landing_pages/leavebehind";
import ServiceAreas from "../components/landing_pages/serviceareas";
import TimeNot from "../components/landing_pages/timenot";
import Marquee from "../components/landing_pages/marquee";
import ReviewsCarousel from "../components/landing_pages/reviews";
import About from "../components/landing_pages/about";
import PricingTables from "../components/pricing_tables";
import Contact from "../components/landing_pages/contact";

function Landing() {
  return (
    <div className="bg-black">
      <Hero />
      <Ribbon />
      <About />
      <section
        className="pb-8 bg-black"
        id="vision-mission"
        style={{
          padding: "40px 20px",
          textAlign: "center",
        }}
      >
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <h2 className="text-3xl font-bold text-gray-50 mb-8 uppercase">
            Our Vision
          </h2>
          <p
            className="pb-8 font-semibold text-gray-300"
            // style={{ fontSize: "1.2em", color: "#555" }}
          >
            To lead the coffee market in promoting health and wellness by
            offering premium, organic coffee powders that nourish the body and
            energize the mind.
          </p>

          <h2 className="text-3xl font-bold text-gray-50 mb-8 uppercase">
            Our Mission
          </h2>
          <p
            className="pb-8 font-semibold text-gray-300"
            // style={{ fontSize: "1.2em", color: "#555" }}
          >
            To inspire and elevate coffee moments by providing every home with
            the finest, most flavorful coffee powder, crafting unique
            experiences in every cup.
          </p>
        </div>
      </section>

      <PricingTables />
      <TimeNot />
      <Marquee />
      {/* <LeaveBehind /> */}
      {/* <ServiceAreas /> */}
      <div  className="py-16"> 

      <h2 id="feeds" className="text-3xl font-bold text-white mt-8 ">
        Our Happy Customers
      </h2>
      <ReviewsCarousel /></div>
      <Contact/>
    </div>
  );
}

export default Landing;

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarsRow from "./stars";
import { getReviewData } from "../utility";
import { Button, Label, Modal, TextInput, Textarea } from "flowbite-react";
import ReactStarsRating from "react-awesome-stars-rating";
import { useMutation, useQuery } from "urql";
import { AddReviewMutation, GetReviewsQuery } from "../../queries";
import { useToasts } from "react-toast-notifications";
const ReviewsCarousel = () => {
  const { addToast } = useToasts();
  const [getReviews, executeGetReviews] = useQuery({
    query: GetReviewsQuery,
    pause: true,
  });
  const [mutation, executeMutation] = useMutation(AddReviewMutation);
  const { data: getReviewData, fetching: getReviewsFetching } = getReviews;
  const [reviewContent, setReviewContent] = useState({ review: [] });
  const [review, setReview] = useState({});
  const [reviewModal, setReviewModal] = useState(false);

  const settings = {
    dots: true,
    useRef: null,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    nextArrow: null,
    prevArrow: null,
    appendDots: (dots) => (
      <div>
        <ul style={{ marginBottom: "-40px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderCardStyle = {
    innerHeight: "500px",
  };
  const onCloseModal = async () => {
    setReviewModal(false);
    setReview({});
  };
  const handleAddReview = async () => {
    try {
      const add = await executeMutation({ ...review });
      if (add?.data?.insert_reviews_one?.id) {
        executeGetReviews({ requestPolicy: "network-only" });
        addToast("Thank you for your valuable feedback", {
          appearance: "success",
        });
        onCloseModal();
      }
    } catch (e) {
      console.log(e, "error");
      addToast("failed to add. please try again", { appearance: "error" });
    }
  };
  useEffect(() => {
    executeGetReviews({ requestPolicy: "network-only" });
  }, []);
  return (
    <div className="bg-white-50 ">
      <h2 className="text-3xl font-bold text-gray-900 mb-8 uppercase">
        {reviewContent.title}
      </h2>
      <div className="max-w-7xl mx-auto pb-4 w-11/12">
        {getReviewsFetching ? (
          <div>Loading...</div>
        ) : (
          <div>
            <Slider  arrows {...settings} autoplay>
              {getReviewData?.reviews &&
                getReviewData?.reviews?.map((review, index) => {
                  return (
                    <div key={index} style={sliderCardStyle} className="px-2">
                      <div className="bg-white shadow-lg p-6 rounded-lg text-left">
                        <span className="flex items-center">
                          <StarsRow rating={review?.star} />
                          <small className="ml-2 mb-2 flex items-center font-semibold font-slate-500">
                            {new Date(review?.created_at).toLocaleDateString()}
                          </small>
                        </span>
                        <p className="text-sm pb-2  font-semibold font-slate-500">
                          {review?.comment}
                        </p>
                        <p className="text-sm  font-semibold font-slate-500">
                          — {review?.name}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </Slider>
            <div className="flex justify-end ">
              <Button
                className="bg-gray-800 text-gray-50 w-full mt-16  hover:bg-gray-900 md:w-fit md:mt-4"
                color={"#422006"}
                onClick={(e) => {
                  e.preventDefault();
                  setReviewModal(true);
                }}
              >
                Add Feedback
              </Button>{" "}
            </div>
          </div>
        )}
      </div>

      <Modal show={reviewModal} size="md" onClose={onCloseModal} popup>
        <Modal.Header />
        <Modal.Body>
          <div className="space-y-6">
            <h3 className="text-xl font-medium text-gray-800 dark:text-white">
              Add Your Feedback{" "}
            </h3>

            <div>
              <div className="mb-2 ">
                <ReactStarsRating
                  onChange={(value) => {
                    setReview({ ...review, star: value });
                  }}
                  value={review?.star ?? 0}
                  className="flex"
                  size="30"
                />
              </div>
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="" value="Your Name" />
              </div>
              <TextInput
                id="name"
                placeholder="Your Name"
                value={review?.name}
                onChange={(event) =>
                  setReview({ ...review, name: event.target.value })
                }
                required
              />
            </div>
            <div>
              <div className="mb-2 block">
                <Label htmlFor="comment" value="Your Feedback" />
                <Textarea
                  placeholder="Add Your Feedback"
                  id="comment"
                  value={review?.comment}
                  onChange={(e) => {
                    setReview({ ...review, comment: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="bg-gray-900 text-gray-50 hover:bg-gray-900"
            color={"#422006"}
            onClick={(e) => {
              e.preventDefault();
              handleAddReview();
            }}
          >
            Add Feedback
          </Button>
          <Button color="gray" onClick={() => onCloseModal()}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReviewsCarousel;

import React, { useState, useEffect, useRef } from "react";
import { getTimeNotData } from "../utility";
import CallToAction from "../cta";

const TimeNot = () => {
  const [timeNotContent, setTimeNotContent] = useState({});

  useEffect(() => {
    const data = getTimeNotData();
    if (data) {
      setTimeNotContent(data);
    }
  }, []);
  const videoRef = useRef(null);
  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.play();
          } else {
            videoElement.pause();
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    observer.observe(videoElement);

    // Cleanup observer on component unmount
    return () => {
      observer.unobserve(videoElement);
    };
  }, []);

  return (
    <div className="bg-black mx-auto items-center justify-center md:py-12 md:px-32">
      {/* Left Column */}
      <div className="w-full flex items-center justify-center px-2 ">
        <video ref={videoRef} className="w-full">
          <source src="/Adver vedio 1.1.mp4" type="video/mp4" />
        </video>
      </div>

      {/* Right Column */}
      {/* <div className="md:w-1/2 w-11/12 p-4 md:py-0 py-12">
        <h2 className="text-3xl font-bold mb-2 text-gray-700 text-left mb-8">
          {timeNotContent.title}
        </h2>
        <p className="mb-8 text-slate-800 font-semibold text-left">
          {timeNotContent.description}
        </p>
        <div className="flex space-y-4 justify-center">
          {timeNotContent.buttons &&
            timeNotContent.buttons.map((button, index) => (
              <CallToAction key={index} link={button.link} text={button.text} />
            ))}
        </div>
      </div> */}
    </div>
  );
};

export default TimeNot;

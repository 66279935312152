import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

const ProjectSlider = () => {
  const projects = [
    { id: 1, image: "/roasted.jpeg", title: "Roasted Coffee Powder" },
    { id: 2, image: "/herbal.jpeg", title: "Herbal Coffee Powder" },
  ];

  return (
    <div  id="products" className="flex flex-col items-center justify-center h-screen bg-black text-white p-6">
      <h2 className="text-4xl font-bold mb-6">Our Products</h2>

      <Swiper
        modules={[Navigation, Pagination, EffectCoverflow]}
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3} // Show 3 slides at a time (adjust for mobile)
        spaceBetween={20}
        loop={true}
        navigation={true}
        pagination={{ clickable: true }}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
        className="w-full"
      >
        {projects.map((project) => (
          <SwiperSlide key={project.id} className="flex justify-center text-white">
            <div className="relative h-full">
              <img
                src={project.image}
                alt={project.title}
                loading="lazy" // Lazy loading enabled
                className="w-full h-full object-cover rounded-lg"
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity">
                <h3 className="text-xl font-semibold">{project.title}</h3>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProjectSlider;

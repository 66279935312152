import React from "react";

const Contact = () => {
  return (
    <section id="contact" className="bg-gray-900 text-white py-16 px-6 md:px-20">
      <div className="max-w-5xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-10">
        {/* Contact Info Section */}
        <div className="space-y-8">
          <div className="text-2xl font-bold">Let’s Connect Over Coffee! ☕</div>
          <div className="mt-4 text-gray-400">
            Have a question, feedback, or collaboration idea? We’d love to hear
            from you! Whether it’s about our coffee selection or services, drop
            us a message, and we’ll get back to you as soon as possible.
          </div>
          <div className="mt-6 space-y-4">
            <div>
              <h4 className="font-semibold">📍 Visit Us</h4>
              <p className="text-gray-400">No. 15/7, Kiringadeniya, Mawanella, Sri Lanka</p>
            </div>
            <div>
              <h4 className="font-semibold">📞 Call Us</h4>
              <p className="text-gray-400">+94 76 898 6163</p>
            </div>
          </div>
          <div className="mt-4 text-gray-400">
            We believe great conversations start with great coffee. Let’s talk! ☕✨
          </div>
        </div>

        {/* Chat Options Section */}
        <div className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-6">
          <div className="text-xl font-bold">💬 Chat with Us</div>
          <p className="text-sm font-normal text-gray-400">
            Reach out instantly via WhatsApp or Messenger.
          </p>
          <div className="space-y-4">
            <a
              href="https://wa.me/94788530806"
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full p-3 bg-green-600 hover:bg-green-500 text-white font-semibold rounded-md transition-all flex justify-center items-center gap-2"
            >
              📱 Chat on WhatsApp
            </a>
            <a
              href="https://m.me/mohammed.rahmy.52"
              target="_blank"
              rel="noopener noreferrer"
              className="block w-full p-3 bg-blue-600 hover:bg-blue-500 text-white font-semibold rounded-md transition-all flex justify-center items-center gap-2"
            >
              💬 Chat on Messenger
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
